import React, { useState } from "react";
import "../styles/projects.css";

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(0);
  const projects = [
    {
      bgImage: "./img/libertyBabyDemo2min.mp4",
      thumbnailImage: "./img/lbmb.jpg",
      headline: "Liberty Baby",
      description: "Liberty Baby Saves the Globe is a 2D action adventure rogue-lite. Expose the dark secrets of GloboCorp. Restore truth, justice, and humanity to Mostly Peaceville.",
      link: "https://www.mostlypeaceful.games/",
    },
    {
      bgImage: "./img/TSHD_bgvideo_short.mp4",
      logoImage: "./img/toysoldiers_logo.png",
      thumbnailImage: "./img/ts_thumb.jpg",
      logoAltText: "Toy Soldiers HD | Open Source TS Engine and Tools",
      headline: "Toy Soldiers HD | Open Source",
      description: "Finished up Toy Soldiers HD and currently working on moving the old Sig Engine tools to open source.",
      link: "https://store.steampowered.com/app/262120/Toy_Soldiers_Complete/",
    },
    {
      bgImage: "./img/machineTakeTwo.mp4",
      thumbnailImage: "./img/mactmb.jpg",
      headline: "Machine is an emergent RPG",
      description: "Machine is a no install, web-based RPG featuring generative, emergent gameplay. The game harnesses AI and player interactions to dynamically build a persistent and evolving story and universe.",
      link: "https://rumble.com/v4961ju-machine-ai-2.html",
    },

      // {
      //   bgImage: "./img/npcColonizer.mp4",
      //   thumbnailImage: "./img/npcColonizer_thumb.jpg",
      //   headline: "NPC Colonizer 3000",
      //   description: "In NPC Colonizer 3000, lead your team of NPCs to colonize new worlds and build thriving communities. Overcome challenges and create your own utopia.",
      //   link: "https://www.npccolonizer.com/",
      // },
  ];

  const handleThumbnailClick = (index) => {
    setSelectedProject(index);
  };

  const isVideo = (path) => {
    return path.endsWith(".mp4");
  };

  return (
    <div className="projects-wrapper">
      <h2>newProjects</h2>
      <p className="project-description">stuff im working on solo</p>

      <div
        className={`featured-project ${
          isVideo(projects[selectedProject].bgImage) ? "" : "image-background"
        }`}
      >
        {isVideo(projects[selectedProject].bgImage) ? (
          <video
            key={projects[selectedProject].bgImage}
            autoPlay
            loop
            muted
            className="featured-background"
          >
            <source src={projects[selectedProject].bgImage} type="video/mp4" />
          </video>
        ) : (
          <div
            className="featured-background"
            style={{
              backgroundImage: `url(${projects[selectedProject].bgImage})`,
            }}
          />
        )}
        <a
          href={projects[selectedProject].link}
          target="_blank"
          rel="noopener noreferrer"
          className="project-content-link"
        >
          <div className="project-content">
            <img
              src={projects[selectedProject].logoImage}
              alt={projects[selectedProject].logoAltText}
              className="project-logo"
            />
            <h3>{projects[selectedProject].headline}</h3>
            <p>{projects[selectedProject].description}</p>
            <span className="more-info">More Information</span>
          </div>
        </a>
      </div>
      <div className="projects-grid">
        {projects.map((project, idx) => (
          <div
            className="project-thumbnail"
            key={idx}
            onClick={() => handleThumbnailClick(idx)}
          >
            <img
              src={project.thumbnailImage}
              alt={`Thumbnail for ${project.headline}`}
              className="thumbnail-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
