// App.js
import React from 'react';
import Home from './components/home.js';
import LatestBlogPost from './components/LatestBlogPost.js';
import Services from './components/Services.js';
import Skills from './components/Skills.js';
import Projects from './components/Projects.js';
import Footer from './components/Footer.js';
import './App.css';
import './index.css';
import Header from './components/Header.js';
import ImageGrid from './components/ImageGrid.js';

function App() {
  return (
    <div className="App">
           <Skills />
           <div className="main-content">
     <Header />
      <Home />
      <h2>pastExperience</h2>
      <ImageGrid/>
      <Projects />
    
      <Footer/>
      </div>
    </div>
  );
}

export default App;
