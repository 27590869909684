import React from "react";
import {
  SiTypescript,
  SiUnrealengine,
  SiUnity,
  SiReact,
  SiNodedotjs,
  SiCss3,
  SiHtml5,
  SiJavascript,
  SiPython,
  SiAdobephotoshop,
  SiOpenai,
  SiAdobe,
  SiAdobepremierepro,
  SiBabylondotjs,
  SiAutodesk,
  SiXbox,
  SiNintendogamecube,
  SiPlaystation,
  SiSteam,
  SiApple,
  SiAndroid,
  SiNintendoswitch,
  SiAdobecreativecloud,
  SiCsharp,
  SiJira,
  SiSteamdeck,
  SiMongodb,
  SiSolidity,
  SiGithub,
  SiCplusplus,
  SiWindows11,
  SiEthereum,

} from "react-icons/si";
import "../styles/Skill.css";

const Skills = () => {
  const icons = [
    <SiUnrealengine className="icon" size={212} />,
    <SiUnity className="icon" size={212} />,
    <SiReact className="icon" size={212} />,
    <SiNodedotjs className="icon" size={200} />,
    <SiCss3 className="icon" size={200} />,
    <SiHtml5 className="icon" size={200} />,
    <SiJavascript className="icon" size={200} />,
    <SiBabylondotjs className="icon" size={200} />,
    <SiNintendogamecube className="icon" size={200} />,
    <SiAdobecreativecloud className="icon" size={200} />,
    <SiNintendoswitch className="icon" size={200} />,
    <SiSteam className="icon" size={200} />,
    <SiPlaystation className="icon" size={200} />,
    <SiXbox className="icon" size={200} />,
    <SiPython className="icon" size={200} />,
    <SiTypescript className="icon" size={200} />,
    <SiOpenai className="icon" size={200} />,
    <SiAdobe className="icon" size={200} />,
    <SiAutodesk className="icon" size={200} />,
    <SiAdobephotoshop className="icon" size={200} />,
    <SiAdobepremierepro className="icon" size={200} />,
    <SiApple className="icon" size={200} />,
    <SiAndroid className="icon" size={200} />,
    <SiCsharp className="icon" size={200} />,
    <SiJira className="icon" size={200} />,
    <SiMongodb className="icon" size={200} />,
    <SiSolidity className="icon" size={200} />,
    <SiSteamdeck className="icon" size={200} />,
    <SiCplusplus className="icon" size={200} />,
    <SiGithub className="icon" size={200} />,
    <SiWindows11 className="icon" size={200} />,
    <SiEthereum className="icon" size={200} />,


  ];

  return (
    <div className="skills-wrapper">
      <div className="skills-grid-container">
        {icons.map((Icon, idx) => (
          <div className="grid-item-skills" key={idx}>
            {Icon}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;
