import React from 'react';
import { FaTwitter } from "react-icons/fa";
import '../styles/Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-links">
                <a href="https://www.mostlypeaceful.games" className="footer-link">
                    mostlypeaceful.games
                </a>
                <a href="https://twitter.com/peacefulgames" target="_blank" rel="noopener noreferrer" className="footer-link">
                    @peacefulgames
                </a>
                <a href="https://twitter.com/toysoldiersgame" target="_blank" rel="noopener noreferrer" className="footer-link">
                    @toysoldiersgame
                </a>
            </div>
        </footer>
    );
};

export default Footer;
