import React from 'react';
import '../styles/home.css';
import { FaGithub, FaTwitter, FaWordpress } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaSquareXTwitter } from "react-icons/fa6";
import { GrDocumentDownload } from "react-icons/gr";
import libertyBabyIdle from '../styles/libertybabyidle.png'; // Adjust the path as needed

const Home = () => {
    return (
        <div className="home-container">
            <section className="intro-section">
                <h3>D.R. ALBRIGHT</h3>

                <div className="contact-links">
                    <a href="mailto:dr@signalstudios.net" className="contact-link">
                        <MdOutlineEmail style={{ fontSize: '24px' }} /> Contact
                    </a>
                    <a href="./img/DRAlbright_resume-24.pdf" download="DRAlbright_resume-24.pdf" className="contact-link">
                        <GrDocumentDownload style={{ fontSize: '24px' }} /> Resume
                    </a>
                    <a href="https://github.com/mostlypeaceful" target="_blank" rel="noopener noreferrer" className="contact-link">
                        <FaGithub style={{ fontSize: '24px' }} /> GitHub
                    </a>
                    <a href="https://x.com/ToySoldiersGame" target="_blank" rel="noopener noreferrer" className="contact-link">
                        <FaSquareXTwitter style={{ fontSize: '24px' }} /> Twitter
                    </a>
                    <a href="https://mostlypeaceful.games" target="_blank" rel="noopener noreferrer" className="contact-link">
                        <FaWordpress style={{ fontSize: '24px' }} /> Blog
                    </a>
                </div>

                <div className="company-info">
                    <strong>Current: Liberty Baby Saves the Globe | MostlyPeaceful.Games</strong><br />
                    <strong>Former: Founder, President, Creative Director | Signal Studios </strong><br />
                    <strong>Previous: EA, Snowblind Studios, Zipper Interactive, THQ</strong>
                </div>

                <div className="liberty-baby-container">
                    <a href="https://mostlypeaceful.games" target="_blank" rel="noopener noreferrer">
                        <div className="liberty-baby-animation"></div>
                    </a><br />
                    <strong>Liberty Baby Saves the Globe</strong>
                </div>
            </section>

            <section className="highlight-projects">
                <p>Welcome. I am D.R. Albright, a game developer with 20+ years in the industry. Some key highlights:</p>
                <ul>
                    <li>Founded Signal Studios</li>
                    <li>Nominated twice for Strategy G.O.T.Y. at the annual D.I.C.E. awards</li>
                    <li>Created Toy Soldiers, Savage Lands, and Ascend: Hand of Kul</li>
                    <li>Sold over 4 million units and generated over 15 million downloads at Signal</li>
                    <li>Developer and/or lead on multiple large publisher franchises with tens of millions of units in the market</li>
                </ul>
                <p>Before founding Signal Studios, I was involved in more than 20 A.A.A. titles at companies like E.A., Snowblind, and T.H.Q. I offer software, AI, and web development services. Also working on a few solo projects, primarily Liberty Baby. Questions or info? Feel free to reach out.</p>
            </section>
        </div>
    );
};

export default Home;
