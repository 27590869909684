import React from 'react';
import projectsData from '../data/projects.json';
import experienceData from '../data/experience.json';
import "../styles/imageGrid.css";
import { h1 } from 'fontawesome';

const ImageGridItem = ({ item }) => {
    if (!item.boxArt) return null;

    return (
        <div className="image-grid-item">
            <a href={item.link} target="_blank" rel="noopener noreferrer">
                <img src={item.boxArt} alt={item.title} />
            </a>
        </div>
    );
};

const ImageGrid = () => {
    const combinedData = projectsData.concat(experienceData)
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    return (


        <div className="image-grid-container">
            {combinedData.map((item, index) => (
                <ImageGridItem key={index} item={item} />
            ))}
        </div>
    );
};

export default ImageGrid;
