import React from 'react';
import '../styles/Footer.css';

const Header = () => {
    return (
        <header className="header">
            <h2>basedPotato</h2>

            {/* <a href="https://www.mostlypeaceful.games" className="footer-link">
                    mostlypeaceful.games blog
                </a>
            <div className="footer-links">
                <a href="mailto:dr@signalstudios.net" className="footer-link">
                    <FontAwesomeIcon icon={faEnvelope} size="3x" />
                </a>
                <a href="https://twitter.com/peacefulgames" className="footer-link">
                    <FaSquareXTwitter style={{ fontSize: '3em' }} />
                </a>

            </div> */}
        </header>
    );
};

export default Header;
